import { useCallback, useState } from 'react';
import { Typography } from 'Typography';
import { Image } from 'Image';
import { FormEmbed } from '../FormEmbed';
import { type FormCardProps } from './FormCard.types';

export function FormCard({ submittedTitle, submittedDescription, formData, image }: FormCardProps) {
    const [isFormSubmitted, setIsFormSubmitted] = useState<string>(
        window.localStorage.getItem('scholarshipNominationsFormSubmitted') ?? 'false',
    );

    const handleFormSubmission = useCallback(() => {
        setIsFormSubmitted('true');
        window.localStorage.setItem('scholarshipNominationsFormSubmitted', 'true');
    }, []);

    return (
        <div className="mx-auto max-w-[555px] md:max-w-full">
            <div className="relative mx-auto mt-8 flex min-h-[400px] items-center justify-center overflow-hidden rounded-4 bg-white shadow-lg shadow-black/5 md:mb-16 md:min-h-[540px]">
                {isFormSubmitted === 'true' ? (
                    <div className="flex flex-col items-center">
                        <Image {...image} className="mb-5 md:mb-10" />
                        <Typography
                            variant="header2"
                            className="mb-1 text-center font-extrabold text-beige-beyond-beyond-dark md:mb-4"
                        >
                            {submittedTitle}
                        </Typography>
                        <Typography
                            variant="body1"
                            className="max-w-[220px] text-center text-xs leading-[24px] text-beige-beyond-beyond-dark md:max-w-none md:text-md"
                        >
                            {submittedDescription}
                        </Typography>
                    </div>
                ) : (
                    <FormEmbed {...formData} handleFormSubmission={handleFormSubmission} />
                )}
            </div>
        </div>
    );
}
